import { Roles } from '../common/constants';
import Navbar from '../components/Navbar';
import NavbarAdmin from '../components/Navbar/NavbarAdmin';
import { useAppContext } from '../contexts/AppContext';
import { useAuthContext } from '../contexts/AuthContext';

interface Props {
    children: JSX.Element, 
}

function DashboardLayout({children}:Props) {
  const {userData} = useAuthContext();
  const {isModalOpen} = useAppContext(); 
   
  return (
    <div className='flex flex-col h-full'>
        <div className={`hidden lg:flex fixed z-[150] w-full ${!isModalOpen ? '': 'lg:pr-[15px]'}`}>
          {userData?.userRole === Roles.UBER_ADMIN ? <NavbarAdmin/> : <Navbar/>} 
        </div>
        <div className='lg:pt-[86px]'>
             {children}
        </div>
    </div>
  )
}

export default DashboardLayout; 