
import axios from 'axios';
import { api } from '..';
import { Roles } from '../../common/constants';
import { type Role } from '../../common/types';
import { checkBuyerRole } from '../../common/utils/functions';
import { ITEMS } from '../endpoints';

const ItemsService = {
    async getItems(page: any, role: Role, dataParams: any = null) {
        const params = {...page};
        if(checkBuyerRole(role)) {
            const data = {
                data_option: 'ALL',
                search_criteria_list: [
                   
                ]
            } as any;
            if(dataParams) {
                data.search_criteria_list = [...data.search_criteria_list, ...dataParams.search_criteria_list]; 
            }
            const response: ResponseDataPaginationI = await api.post(ITEMS.GET_ITEMS, data, {params}); 
            return response; 
        } else {
            const response: ResponseDataPaginationI = await api.get(ITEMS.GET_MODELS, {...params}); 
            return response; 
        }
    },
    async getModels(page: number = 0) {
        const params = {page};
        const response: ResponseDataPaginationI = await api.get(ITEMS.GET_MODELS, {...params}); 
        return response; 
    },
    async getModelById(id: string) {
        const response = await api.get(`${ITEMS.GET_MODEL}/${id}`);
        return response; 
    }, 
    async getNextModelByCursore(cursor: string) {
        const response: ResponseDataPaginationI = await api.get(`${ITEMS.GET_MODEL}?size=1&${cursor}`);
        return response; 
    }, 
    async getNextItemsByCursore(cursor: string) {
        const response: ResponseDataPaginationI = await api.post(`${ITEMS.GET_ITEMS}?size=1&${cursor}`, { search_criteria_list: []});
        return response; 
    }, 
    async getItemById(id: string) {
        const response = await api.get(`${ITEMS.GET_ITEMS}/${id}`);
        return response; 
    }, 
    async startStream(id: string) {
        const response: {signed_link: string} = await api.get(`${ITEMS.GET_ITEMS}/${id}/stream`);
        return response; 
    }, 
    async getVideoByModelId(id: string) {
        const response = await api.get(`${ITEMS.MODELS_BASE}/${id}/video`);
        return response; 
    }, 
    async getItemByToken() {
        const response = await api.get(`${ITEMS.GET_ITEM_BY_TOKEN}`);
        return response; 
    }, 
    async getItemByCode(id: string, code: string = '') {
        const params = {code}; 
        const response = await api.get(`${ITEMS.GET_ITEMS}/${id}/code`, {...params});
        return response; 
    }, 
    async getFilters() {
        const response: FiltersListI = await api.get(ITEMS.GET_FILTERS);
        return response; 
    }, 
    async deleteModelById(id: string) {
        const response = await api.delete(`${ITEMS.DELETE_MODEL}/${id}`);
        return response; 
    }, 
    async getFilteredItems(page: any, paramsData:any) {
        const params = {...page};
        const response: ResponseDataPaginationI = await api.post(ITEMS.SEARCH_MODELS, paramsData, {params}); 
        return response;
    },
    async getModelCategory(search: string) {
        const params = {search}; 
        const response: ResponseDataPaginationI = await api.get(ITEMS.MODELS_CATEGORY, {...params});
        return response;  
    },
    async getModelBrand(search: string) {
        const params = {search}; 
        const response: ResponseDataPaginationI = await api.get(ITEMS.MODELS_BRAND, {...params});
        return response;  
    },
    async getModelMaterial() {
        const response: ResponseDataPaginationI = await api.get(ITEMS.MODELS_MATERIAL);
        return response;  
    },
    async getModelCollection(search: string) {
        const params = {search}; 
        const response: ResponseDataPaginationI = await api.get(ITEMS.MODELS_COLLECTION, {...params});
        return response;  
    },
    async getModelType(search: string) {
        const params = {search}; 
        const response: ResponseDataPaginationI = await api.get(ITEMS.MODELS_TYPE, {...params});
        return response;  
    },
    async createModelCategory(name:string) {
        const response: {id:string} = await api.post(ITEMS.MODELS_CATEGORY, {name}); 
        return response;
    },
    async createModelCollection(name:string) {
        const response: {id:string} = await api.post(ITEMS.MODELS_COLLECTION, {name}); 
        return response;
    },
    async createModelBrand(name:string) {
        const response: {id:string} = await api.post(ITEMS.MODELS_BRAND, {name}); 
        return response;
    },
    async createModelType(name:string) {
        const response: {id:string} = await api.post(ITEMS.MODELS_TYPE, {name}); 
        return response;
    },
    async createItem(data: any) {
        return await api.post(ITEMS.CREATE_MODEL, data, { headers: {
            'Content-Type': 'multipart/form-data',
          }}); 
    },
    async uploadVideo(data: any, params: any) {
        const response = await api.post(`${ITEMS.UPLOAD_VIDEO}/`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            params: {
                ...params
            }
    }); 
        return response;
    },
     async createCode(email: string, type: 'SELL_ITEM' | 'CANCEL_LOAN') {
        const params = {buyerEmail: email, type}; 
        const response = await api.post(ITEMS.SEND_CODE, {}, {params}); 
        return response;
    },
    async sellItem(data: any) {
        const response = await api.post(ITEMS.SELL_ITEM, {...data}); 
        return response;
    }, 
     async validateCode(email: string, type: 'SELL_ITEM' | 'CANCEL_LOAN', code: string) {
        const params = {buyerEmail: email, type, code}; 
        const response = await api.post(ITEMS.CHECK_CODE, {}, {params}); 
        return response;
    },
    async createUserByDealer(data: any) {
        const response = await api.post(ITEMS.CREATE_USER_BY_DEALER, {...data}); 
        return response;
    }, 
    async getCoordinates(id: string) {
        const response: MapDataInterface[] = await api.get(`${ITEMS.GET_COORDINATES}/${id}`);
        return response;  
    },
    async sendBuyerData(data: any) {
        const response = await api.post(ITEMS.SALES_DATA, {...data}); 
        return response;
    }, 
    async getQrCodeData(id: string) {
        const response: any = await api.get(`${ITEMS.GET_ITEMS}/${id}/qr_code`);
        return response;  
    },
    async getAbilityToBuy(count: number) {
        const params = {count}; 
        const result: {ability: boolean} = await api.get(ITEMS.ABILITY_BUY, {...params});
        return result;
    },
    async getAbilityToSell(count: number) {
        const params = {count}; 
        const result: {ability: boolean} = await api.get(ITEMS.ABILITY_SELL, {...params});
        return result;
    },
    async faqSend(data: any) {
        const response = await api.post(ITEMS.FAQ_SEND, {...data}); 
        return response;
    }, 
    async sendFingerprintsImages(data: any, id: string) {
        const response = await api.put(`${ITEMS.SEND_FINGERPRINTS_IMAGES}/${id}/fingerprints`, data, { headers: {
            'Content-Type': 'multipart/form-data',
          }}); 
        return response;
    }, 
    async inviteBorrower(borrower_email: string) {
        const response = await api.post(ITEMS.INVITE_BORROWER, {borrower_email}); 
        return response;
    },
    async itemsLoan(data: any) {
        const response = await api.post(ITEMS.ITEMS_LOAN, {...data}); 
        return response;
    },
    async cancelLoan(itemId: string) {
        const response = await api.post(`${ITEMS.CANCEL_LOAN}/${itemId}/cancel`, {}); 
        return response;
    },
    async getDraft(id: string) {
        const response: DraftData = await api.get(`${ITEMS.GET_DRAFT}/${id}`);
        return response;  
    },
    async deleteDraft(id: string) {
        const response = await api.delete(`${ITEMS.BASE_LOAN}/${id}/draft`);
        return response; 
    }, 
    async approveByCurator(id: string) {
        const response = await api.put(`${ITEMS.BASE_LOAN}/${id}/curator/activate`, {}); 
        return response;
    }, 
    async approveByMain(id: string) {
        const response = await api.put(`${ITEMS.BASE_LOAN}/${id}/main/activate`, {}); 
        return response;
    }, 
    async rejectByCurator(id: string) {
        const response = await api.put(`${ITEMS.BASE_LOAN}/${id}/curator/reject`, {}); 
        return response;
    }, 
    async rejectByMain(id: string) {
        const response = await api.put(`${ITEMS.BASE_LOAN}/${id}/main/reject`, {}); 
        return response;
    }, 
    async setConnectDR(id: string) {
        const response: any = await api.get(`${ITEMS.SET_CONNECT_DR}/${id}`);
        return response;  
    },
    async checkOpenDRConnect(id: string) {
        const response: {availability: boolean} = await api.get(`${ITEMS.CHECK_OPEN_DR_CONNECT}/${id}`);
        return response;  
    },
    async dropDRConnection(id: string) {
        const response = await api.delete(`${ITEMS.DROP_DR_CONNECTION}/${id}`);
        return response; 
    }, 
    async revokeLoan(id: string) {
        const response = await api.put(`${ITEMS.BASE_LOAN}/${id}/revoke_cancellation`, {}); 
        return response;
    },
    async assignItemToExpert(data: any) {
        const response: {id: string} = await api.post(ITEMS.ASSIGN_EXPERT, {...data}); 
        return response;
    },

    async getExpertises(expert_id: string, page: any, size: number, data?: any) {  
/*             const data = {
                data_option: 'ALL',
                search_criteria_list: [
                   
                ]
            } as any; */

    
            const response: any = await api.post(`${ITEMS.ASSIGN_EXPERT}/search?expertId=${expert_id}&page=${page}&size=${size}`, data, { headers: {
                'Content-Type': 'multipart/form-data',
              }});
 
            return response; 
        
    },
    async getCurrentExpertise(id: string) {
        const response: any = await api.get(`${ITEMS.ASSIGN_EXPERT}/${id}`);
        return response;  
    },
    async deleteCurrentExpertise(id: string) {
        const response = await api.delete(`${ITEMS.ASSIGN_EXPERT}/${id}/cancel`);
        return response; 
    }, 
    async updateExpertises(data: any) {
        const response: {id: string} = await api.put(ITEMS.ASSIGN_EXPERT, {...data}); 
        return response;
    },
    async createExpertEvaluation(data: any) {
        const id: string =  await api.post(ITEMS.EXPERT_EVALUATIONS, data, { headers: {
            'Content-Type': 'multipart/form-data',
          }}); 
        return id;   
    },
    async getExpertiseByItemId(id: string) {
        const response: any = await api.get(`${ITEMS.GET_EXPERTISE_BY_ITEM_ID}?itemId=${id}`);
        return response;  
    },
    async getEvaluationsByItemId(id: string) {
        const response: any = await api.get(`${ITEMS.GET_EVALUATIONS_BY_ITEM_ID}/${id}`);
        return response;  
    },
    async deleteEvaluationImage(imageId: string) {
        const response = await api.delete(`${ITEMS.EXPERT_EVALUATIONS_MEDIA}/${imageId}`);
        return response; 
    }, 
    async cancelSignDocument(evaluationId: string) {
        const response = await api.delete(`${ITEMS.EXPERT_EVALUATIONS}/${evaluationId}/cancel_signing_document`);
        return response; 
    }, 
    async updateEvaluation(data: any) {
        const response: {id: string} = await api.put(ITEMS.EXPERT_EVALUATIONS,  data, { headers: {
            'Content-Type': 'multipart/form-data',
          }}); 
        return response;
    },
    async getActiveExpertise(id: string) {
        const response: any = await api.get(`${ITEMS.ASSIGN_EXPERT}/item/${id}/active`);
        return response;  
    },
    async requestForIncreaseExpirePeriod(expertiseId: string) {
        const response: {id: string} = await api.post(`${ITEMS.ASSIGN_EXPERT}/${expertiseId}/extend_expire_time`, {}); 
        return response;
    },
    async getFingerprints(itemId: string) {
        const response: Fingerprint[] = await api.get(`${ITEMS.GET_ITEMS}/${itemId}/fingerprints_preview`);
        return response; 
    }, 
    async sendAnalyzeData(id: string, data: any) {
        const response =  await api.post(`${ITEMS.ANALIZE_FINGERPRINTS}/${id}`, data, { headers: {
            'Content-Type': 'multipart/form-data',
          }}); 
        return response;   
    },
    async getVerificationHistory(itemId: string) {
        const response: VerificationHistoryI[] = await api.get(`${ITEMS.ANALIZE_ITEM_HISTORY}/${itemId}`);
        return response; 
    }, 
    async saveProvenance(data: any, id: string) {
        const response = await api.put(`${ITEMS.MODELS_BASE}/${id}/upload_provenance`, data, { headers: {
            'Content-Type': 'multipart/form-data',
          }}); 
        return response;
    }, 
    async sendToSignDocument(evaluationId: string) {
        const response: {id: string} = await api.post(`${ITEMS.EXPERT_EVALUATIONS}/${evaluationId}/send_signature_email`, {}); 
        return response;
    },
    async getProvenance(modelId: string) {
        const response: ProvenanceI = await api.get(`${ITEMS.MODELS_BASE}/${modelId}/provenance`);
        return response; 
    }, 
    async getVideo(id: string) {
        const token = sessionStorage.getItem('token'); 
        const url = process.env.REACT_APP_STREAMING_URL; 
        // const url = 'https://video-worker.nikolaykolodyazhnii94.workers.dev/'; 
        const response: any = await axios.get(`${url}/?itemId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                Authorization: `Bearer ${token}`, 
                'Access-Control-Allow-Origin': '*',
            }
        });
        return response; 
    }, 
    async checkUserSubscriptionLimits(userId: string) {
        const response: CheckLimitData = await api.get(`${ITEMS.USER_SUBSCRIPTION_LIMITS}/${userId}`);
        return response; 
    }, 
    async getCertificateLink(modelId: string) {
        const response: {link: string | null} = await api.get(`${ITEMS.GET_CERTIFICATE}/${modelId}`);
        return response; 
    }
};

export default ItemsService; 