const EN = {
    login: {
        need_help: 'Need help?',
        create_password: 'Create new password', 
        password: 'Password',
        error_password: 'Your password must be at least 6 characters.', 
        re_password: 'Re-enter password', 
        error_re_password: 'Please make sure your passwords match.'
    },
    navbar: {
        items: 'Objects',
        profile: 'Profile',
        more: 'More',
        people: 'People',
        inventory: 'Company',
        option_1: 'About NFTrends',
        option_2: 'Contact us',
        option_3: 'Help center',
        option_4: 'Subscription',
    },
    footer: {
        privacy_policy: 'Privacy policy',
        terms_of_use: 'Terms of Use', 
        intelectual_protection: 'Intellectual Property Protection'
    },
    profile: {
        title: 'Profile',
        title_mobile: 'Profile information',
        first_name: 'First name',
        last_name: 'Last name',
        company_name: 'Company name',
        company: 'Company',
        role: 'Role', 
        account_role: 'Account role',
        assign_inventory: 'Assign company',
        contact_details_title: 'Contact details',
        client_details: 'Client details',
        email: 'E-mail',
        country: 'Country',
        city: 'City',
        city_town: 'Town/City',
        street: 'Street',
        street_address: 'Street address',
        street_name_number: 'Street name and number', 
        street_name: 'Street name',
        province: 'State/ Province/ Region', 
        province_area: 'Area/District', 
        province_state: 'State',
        province_canada: 'Province',
        address_line: 'Address line',
        building: 'Building',
        building_name: 'Building name or №',
        suite: 'Suite', 
        zip_code: 'Zip code',
        settings: 'Settings',
        settings_mobile: 'Language and currency information',
        language: 'Language',
        currency: 'Currency',
        n_build: '№ Building',
        phone_number: 'Phone number',
        save: 'Save',
        zip_code_2: 'Zip-code',
        post_code: 'Zip-code / Postcode', 
        post_code_base: 'Postcode',
        plz: 'PLZ',
        cancel: 'Cancel',
        change_email: 'Change e-mail',
        change_password: 'Change password',
        current_session: 'Current session', 
        terminate_session: 'Terminate all other sessions', 
        previous_session: 'Previous sessions',
        delete_account: 'Delete account',
        resset_password: 'Reset password', 
        logout: 'Log out',
        terminate_session_btn: 'Terminate Session',
        delete_title: 'Delete Account?',
        delete_text: 'Deleting account will do the following:', 
        delete_sub_text_1: '- Log you out on all devices after approve by admin',
        delete_sub_text_2: '- Delete all of your account information', 
        info_title: 'Your request to delete your account will be sent',
        info_text: 'Wait for the confirmation email',
        change_password_title: 'Check your e-mail',
        change_password_text: 'We sent you instructions to reset  password',
        reset_password_title: 'Password was reset.',
        reset_password_text: '{{name}} will get email with further instructions',
        success_code_text: 'Your device code has been successfully generated. We have also sent this code to your email address.'

    },
    items: {
        item_list: 'Object list',
        model_list: 'Model list',
        add_item_title: 'Add object info',
        add_model_title: 'Add model info',
        add_model_title_2: 'Add object / model info',
        model_name: 'Model name', 
        model_name_2: 'Object / model name',
        item_name: 'Object name', 
        item_details: 'Object details',
        brand: 'Brand',
        nft_type: 'Type of NFT',
        type_placeholder: 'Physical / Digital', 
        msrp: 'MSRP({{currency}})',
        currency: 'Currency',
        msrp_base: 'MSRP', 
        category: 'Category',
        online: 'Online',
        type: 'Type',
        purchase_date: 'Purchase date',
        collection: 'Collection',
        description: 'Description',
        description_base: 'Description', 
        distribution: 'Distribution',
        import_xlsx: 'Import (XLSX or CSV)',
        location: 'Location',
        serial_number: 'Serial number',
        catalog_number_optional: 'Catalog number (optional)',
        meta_data: 'Meta Data',
        z_axis: 'Z axis',
        y_axis: 'Y axis',
        x_axis: 'X axis',
        magnification: 'Magnification',
        input_full: 'You can choose only one digital art',
        prominent_part: 'Prominent part {{order}}',
        serial_number_optional: 'Serial number (optional)',
        add_serial_number: 'Add serial number',
        add_photos: 'Add photos',
        limited_edition_size: 'Limited Edition Size',
        upload_photo: 'Click to upload photo',
        upload_file: 'Click to upload files',
        upload_license: 'Click to upload license',
        upload_document: 'Click to upload document',
        upload_photo_fingerprint: 'Click to upload fingerprints',
        upload_photo_fingerprint_2: 'Click to upload object’s fingerprints',
        upload_nfdi_photo: 'Please, upload image',
        add_item: 'Add object', 
        add_model_btn: 'Add model',
        add_model_btn_1: 'Add object / model',
        description_origin: 'Description of location',
        price: 'Price',
        display_remotelly: 'Display Remotely',
        organization: 'Organization',
        company: 'Company',
        add_digital_art: 'Add digital art',
        take_video: 'Take video',
        
        upload_digital_art: 'Click to upload digital art',
        title_digital_dialog_1: 'Please wait', 
        text_digital_dialog_1: 'Working on creating Digital Passport', 
        title_digital_dialog_2: 'Done', 
        text_digital_dialog_2: 'Digital Passport has been created', 
        text_digital_dialog_3: 'You may close the dialogue or navigate back to the models. A new model will be available within a few minutes.',
        items_registered: '{{items}} objects', 
        item_registered: '{{items}} object', 
        items_registered_short: '{{items}} objects', 
        add_item_btn: 'Add object',
        sell_item_btn: 'Sell object',
        sell_items_count: '{{items}} object to sell',
        sell_items_count_many: '{{items}} objects to sell',
        sell_items_count_2: 'Sell object ({{items}})',
        search: 'Search',
        filters: 'Filters',
        digital_passport: 'Digital passport',
        digital_product_passport: 'Digital product passport',
        details: 'Details',
        models: '{{models}} models registered',
        available: '{{items}} available',
        purchased: 'Purchased',
        historical: 'Historical',
        item_status: 'Object status',
        loans: 'Loans', 
        clear: 'Clear',
        apply: 'Apply', 
        text_not_result_1: 'We couldn’t find a match for “{{name}}”',
        text_not_results:  '{{type}} not found.',
        text_not_result_2: 'Pease try another search.',
        all_serial_numbers: 'All serial numbers',
        search_serial_number: 'Search by object serial number',
        back: 'Back',
        delete: 'Delete',
        item_availability: 'Object availability',
        history: 'Object history',
        created: 'Created',
        model_detail: 'Model detail',
        item_detail: 'Object details',
        owned: 'Owned',
        scan_text: 'Scan to verify details',
        owner: 'Owner',
        blockchain_link: 'Blockchain link',
        blockchain_generating_text: 'The blockchain link is being generated, it may take a few seconds...',
        public_details: 'Public details',
        delete_item_title: 'Delete this model ?',
        delete_btn_model: 'Delete model',
        delete_inventory_btn: 'Delete company', 
        delete_item_text_1: 'Are you sure you want to delete object?',
        delete_item_text_2: 'They can’t be restored.',
        success_delete_title: 'Model is deleted',
        success_delete_btn: 'Back to the model list', 
        no_item: 'No object available',
        items_to_sell: 'Objects to sell',
        item_to_sell: 'Object to sell',
        next: 'Next', 
        next_step: 'Next step',
        sell: 'Sell',
        no_items_to_sell: 'No object to sell', 
        no_items_text: 'You don\'t have any objects to sell',
        pick_client_title: 'Pick client to sell',
        new_client: 'New client',
        existing_client: 'Existing client',
        borrower_info: 'Borrower information',
        invitation_success: 'Invitation was successfully sending.', 
        new_user: 'New user',
        cancel_loan_text: 'Are you sure you want to cancel the loan? This action might have legal and financial implications for your company.',
        delete_draft_text: 'Are you sure you want to delete the draft?',
        existing_user: 'Existing user',
        profile_information: 'Profile information', 
        items_qr_code_valid: 'The QR code will be valid for ',
        unavailable: 'Unavailable',
        prev_item: 'Previous object',
        next_item: 'Next object',
        go_to_list: 'Go to object list',
        prev_object: 'Previous object',
        next_object: 'Next object',
        go_to_object: 'Go to object list',
        client_information: 'Client Information',
        continue: 'continue',
        loan_item: 'Loan object', 
        loan_item_draft: 'Loan (draft)', 
        enter_email: 'Enter borrower\'s email', 
        send_invitation: 'Send invitation',
        check_email: 'Check email',
        loan_status: 'Borrowed',
        owned_status: 'Owned', 
        info_catalog_number: 'You can choose the number of copies of your digital art by entering a number in the input field next to catalog number',
        info_digital_art: 'When you select a video file, we automatically generate thumbnails, but if you want to upload your own thumbnail, you can close the thumbnail.png file and upload your file in one of these extensions: .jpg, .jpeg, .png, .svg.', 
        info_loan_1: 'Existing user - if the user is registered in our system, enter their email in the field for an existing user, and they will receive a confirmation code to this email.', 
        info_loan_2: 'New user - if this user is not in our system, enter their email in the section for a new user, and we will send an invitation for registration to this address.', 
        success_approve: 'You have successfully approved loan',
        approve_btn: 'Go to dashboard',
        type_company: 'Search by company name',
        add_nfdi: 'Add NFDI',
        create_loan_btn: 'Start loan',
        save_draft_btn: 'Save as draft',
        status_loan: 'Loan status',
        display_remotelly_modal_text: 'You have opened "Display remotelly" connection. Do you want to close that connection and open the new one?',
        display_remotelly_modal_btn: 'Open new connection',
        code: 'Code',
        send: 'Send',
        generate_code_btn: 'Generate code',
        transactions_title: 'Transaction blockchain links',
        cancel_info: 'You cannot cancel this loan because the cancellation grace period extends beyond the end date.', 
        reload_warning: 'Reloading the page will interrupt the current sales process. Are you sure you want to proceed?',
        verification: 'Verification results',
        verification_subtitle: 'Choose group of fingerprints', 
        fingerprints: 'Fingerprints', 
        fingerprint: 'Fingerprint', 
        fingerprint_group: 'Group of fingerprints', 
        fingerprints_location: 'Fingerprint location',
        butch_text: 'Locate this area on the object',
        add_video: 'Upload video file',
        add_document: 'Upload document',
        verify_fingerprints_btn: 'Submit for verification', 
        verify_process: 'Verification in progress. It may take a few seconds.',
        expert_info: 'Expert information',
        auth_item_title: 'Authenticate object', 
        item_info: 'Object information', 
        request_opinion: 'Request Expert Opinion',
        submit_opinion: 'Submit Expert Opinion',
        expert_opinion: 'Expert Opinion',
        expertise_history: 'Authentication history',
        expiration_date: 'Expiration date',
        provenance: 'Provenance',
        literature: 'Literature', 
        active_auth_status: 'Active authentication status',
        verification_history: 'Verification history',
        verification_reason: 'This could be due to poor video quality or incorrect subject framing during recording. Please ensure you\'re capturing the right part of the subject with clear, high-quality video.',
        verification_reason_2: 'Your video could not be processed. This may be due to insufficient lighting or improper framing during the recording. Please try recording again, ensuring good lighting and minimizing any light glares.', 
        verification_reason_rejected: 'Your video could not be processed. This may be due to insufficient improper framing or lighting during the recording. Please try recording again, ensuring you have sufficient and even lighting.', 
        create_dpp: 'Create dpp',
        add_provenance: 'Add provenance',
        metadata_tootltip_1: 'Please, add fingerprints and all additional information.',
        metadata_tootltip_2: 'These pictures will be shown to user on the verification page.',
        metadata_tootltip_3: 'This is a mandatory field. For testing purposes, we allow only one fingerprint per object. The full app provides space for up to 20 fingerprints per object.',
        tootltip_magnification: 'Please ignore for now.',
        prominent_part_tooltip: 'These two optional fields in free style allow you to add a description that better anchors the fingerprinted area.',
        brand_tooltip: 'This mandatory field is pre-populated during client onboarding. It is automatically used to populate the Last Name field; however, the Last Name field can be manually overwritten if necessary.',
        description_tooltip: 'This is an optional field, especially during the testing stage. The current titles should be considered reminders to fill in the relevant information, if available.',
        materials_tooltip: 'This is a mandatory field that allows multiple entries. To standardize materials, they are listed in the dropdown menu. If non-listed material emerges, please contact us, and we will expand the list.', 
        authentication_tooltip: `
        Draft: The authentication form was saved without submission. You can edit the form in the future.\n
        Done: Authentication was completed and submitted.\n
        None: No edits were made to the authentication form.`,
        verification_tooltip: `
        Failed: Fingerprint verification failed due to confidence less than 82.5%. You cannot submit the expertise form without verification.\n
        Success: Fingerprint verification was successful (over 82.5%). You can submit the expertise form.\n
        None: Verification has not yet occurred. Please try to verify again.
        `,
        sign_tooltip: `
        Sent: The document to be signed was sent to you via email, please proceed there.\n
        Completed: The document was signed. You can submit the expertise form.\n
        Declined: Your signature is required, please provide it again to complete expertise.\n
        None: Signature has not yet occurred.
        `,
        evaluation_info: 'Evaluation information',
        upload_popup_title: 'Model creation', 
        object_record_creation: 'Object Record Creation',
        upload_popup_text: 'Please wait until all steps will be finished', 
        upload_popup_text_1: 'Complete object form', 
        upload_popup_text_2: 'Upload media assets', 
        upload_popup_text_3: 'Create digital passport', 
        upload_popup_text_4: 'Add information about object', 
        upload_popup_text_5: 'Attach required media', 
        upload_popup_text_6: 'Confirm and check uploaded media', 
        upload_finish_text: 'Great! The Object Record has been created. The blockchain will be done in the background mode. You can now close this window.',
        expertise_submit_warning: 'To submit your expertise, please complete fingerprint verification successfully.', 
        expertise_signing_warning: 'To submit expertise, please check your email to sign a document.', 
        fingerprints_info: 'Please upload a photo, click "Select Area" and choose the fingerprint location. Don`t use the photo that was selected as a fingerprint.',
        add_date: 'Add date',
        add_date_title: 'Creation Date', 
        add_date_text: 'Please specify the production date. You can choose either an exact date or a range, indicated as \'from [date] to [date]\'. Please select month from dropdown list and enter year of production. For historical dates, use \'BCE\' or \'AD\' as appropriate.',
        unknown_label: 'I don`t know exact date.',
        specific_date: 'Specific date', 
        range: 'Range',  
        day: 'Day', 
        month: 'Month',
        year: 'Year', 
        measurements_title: 'Measurements', 
        measurements_text: 'Please choose the unit of measurement: inches (in) or centimeters (cm).', 
        inches: 'Inches (in)',
        centimeters: 'Centimeters (cm)',
        height: 'Height', 
        width: 'Width', 
        depth: 'Depth', 
        first_name: 'First name', 
        middle_name: 'Middle name', 
        last_name: 'Last name', 
        materials: 'Materials', 
        material: 'Material', 
        brand_maker: 'Brand / Maker', 
        maker: 'Maker',
        add_object: 'Add object',
        add_object_bootstrapped: 'Object Title, Name, or Model', 
        object_list: 'Object list',
        artist: 'Artist',
        date_title: 'Date of period', 
        object_availability: 'Object availability',
        click_here: 'Click here',
        check_certificate: 'Check certificate', 
        certificate_not_found: 'Certificate not found',
    },
    pick_client: {
        text_btn: 'Verify client account', 
        enter_existing_email: 'Enter existing clients email', 
        enter_email: 'Enter existing {{user}} email',
        user_email: '{{user}} email',
        change: 'change',
        change_client: 'Change client', 
        verify_client: 'Verify client',
        code_was_send_to: 'Verification code was sent to ',
        enter_code: 'Please, enter the code from e-mail', 
        error_email_text: 'Incorrect e-mail verification code.', 
        resend_code: 'Resend code?', 
        no_get_code: 'Didn\'t you get the code?', 
        click_to_resend: 'Click here to resend.', 
        text_about_spam: 'Make sure to check your spam folder.', 
        code: 'Code', 
        validated: 'Validated',
        client_verified: 'Client is verified',
        show_details: 'show details',
        close_details: 'close details',
        sold_item_btn: 'Back to the object list',
        item_sold_title: 'Object sold', 
        total: 'Total MSRP', 
        public_key: 'Public key',
        prepare_title: 'Prepare your object to sell',
        prepare_text: 'Follow easy 2 steps to prepare your object to sell',
        prepare_text_step: 'Follow easy {{step}} steps to prepare your object to loan',
        option_1_text: 'Choose retailer near you to authenticate your object.',
        option_2_text: 'Add information about buyer',
        choose_on_map: 'Choose on map',
        send_request: 'Send request', 
        retailer: 'Retailer', 
        choose_retailer: 'Choose retailer',
        success_prepare_text: 'Retailer will call you back and set perfect time to collect your object', 
        success_prepare_text_2: 'Retailer will call you back for an appointment',
        back_to_item_list: 'Back to object list', 
        email_error: 'Email format not valid', 
    },
    people: {
        title: 'People',
        add: 'Add',
        add_inventory: 'Add company', 
        add_people: 'Add people',
        search_placeholder: 'Search by name or email',
        search_placeholder_2: 'Search by name, company or email',
        profile: 'Profile',
        inventory: 'Company',
        first_name: 'First name',
        delete_account_text: 'Are you sure you want to delete {{name}} account',
        success_title: 'New account has been created',
        success_text: '{{name}} will get an email with further instructions',
        back_btn_text: 'Back to list',
        delete: 'delete',
        edit: 'edit',
        search_placeholder_inventory: 'Search by company',
        delete_inventory_text: 'Are you sure you want to delete {{name}} company',
        inventory_name: 'Company name',
        assign_brand: 'Assign brands',
        primary_address: 'Primary address',
        secondary_address: 'Secondary address',
        license_number: 'License number',
        main_contact: 'Main contact',
        primary_phone_number: 'Primary phone number',
        country: 'Country',
        ip_address: 'IP address (for digital arts)',
        mfa_identifier: 'Tax ID/Licence number', 
        assign_user_info: 'You can\'t edit the company type if it has assigned users. You need to detach the user(s) from that company in order to enable type editing.',
        add_user_info: 'You won\'t be able to edit the company type if users have been assigned to the company. You will need to detach the user(s) from that company in order to enable type editing.',
        certificate: 'Docusign certificate link', 
    },
    subscription: {
        plan: 'Your subscription plan',
        upgrade_title: 'Upgrade your plan', 
        renew_title: 'Renew your plan',
        basic: 'Basic',
        started: 'started {{date}}',
        next_renewal: 'The next renewal will be on {{date}}',
        renew: 'Renew',
        subscribe: 'Subscribe',
        renew_subscription: 'Renew subscription',
        renew_subscription_2: 'Reinstating', 
        upgrade: 'Upgrade',
        cancel: 'Cancel',
        subscription_history: 'Subscription history',
        suspended: 'Suspended',
        suspended_text: 'To reactivate the suspended service, you need to renew your plan.', 
        download: 'Download',
        option_1: 'Personal account',
        option_2: 'Access to App',
        option_3: 'Articles and news',
        option_4: 'Buying physical objects with DPPs and NFDIs',
        option_5: 'Selling of physical objects with DPPs and NFDIs',
        option_6: 'Loaning of Digital Arts NFTs',
        option_7: 'Plane',
        option_8: 'Loaning of Digital Arts NFTs',
        option_1_2: 'Business account',
        cost: 'Price', 
        tariff_1: 'Basic',
        tariff_2: 'Extended',
        tariff_3: 'Comprehensive',
        tariff_4: 'Supreme', 
        up_to: 'Up to {{value}}', 
        unlimited: 'unlimited',
        individual_plan: 'Individual plan',
        custom_price: '{{Year}} / year no more than {{hour}} hr',
        none: 'None', 
        avaliable: '{{value}} avaliable', 
        unavaliable: 'unavaliable',
        autorenewal: 'Subscription autorenewal', 
        upgrade_plan: 'Upgrade your plan',
        renew_plan: 'Renew your plan',
    },
    landing: {
        features: 'Features',
        how_it_works: 'How it works',
        home: 'Home',
        luxury_market: 'Luxury',
        arts: 'Arts',
        team: 'Team',
        news: 'News',
        solutions: 'Solutions',
        contact_us: 'Contact us',
        partners: 'Partners',
        sign_in: 'Sign in',
        sign_up: 'Sign up',
        see_more: '+ See more',
        see_less: '- See less',
        book_consultation: 'Book Free Consultation',
        artists: 'Artists',
        museums: 'Museums and collectors',
        galeries: 'Museums and Galleries',
        our_team: 'Our team',
        our_team_subtitle: 'Strategy & Business Development', 
        our_team_technology: 'Technology', 
        our_news: 'Our news',
        see_all_news: 'See all news',
        news_text: 'Understand, grow and optimise with our latest insights in NFT',
        name: 'Name',
        investors_art: 'Investors in Digital Art',
        secondary_sales: 'Secondary sales of Luxury and Art',
        image_author_info: 'Art belongs to the author and shown for illustration purpose',
        check_dpp: 'Check the DPP example',
        dpp_secured: 'DPP cryptographically secured',
        btn_digital_text: 'Digital Product Passport',
        btn_hybrid_text: 'NFDI',
        disclaimer: 'Disclaimer',
        go_articles: '<- Go to article',
        text_disclaimer_rolex: 'This image is used only for illustration. Currently, NFTrends has no working relations with the Rolex brand',
        text_disclaimer_art: 'This image is used only for illustration. Currently, NFTrends has no working relations with the artist Markus Åkesson',
        disclaimer_digital_art: 'This Photo by Simon Lee on Unsplash is used only for illustration. Currently, NFTrends has no working relations with Simon Lee. For further information on the artist and his work please click the link',
        text_disclaimer: 'This image is used only for illustration with owner’s permission',
        text_disclaimer_page_1: 'This image is used only for illustration with the brand\'s permission. Currently, NFTrends has no working relations with the La Maison Steel. For further ordering and product information please click the link', 
/*         text_dpp: 'In real life only the holder of the private key has exclusive access to the DPP', */
        text_dpp: 'In real life only the holder of the private key has exclusive access to the DPP',
        luxury_brands: 'Luxury brands',
        text_preview: 'Whether you’re buying a luxury handbag, watch, or a piece of physical or digital art, Digital Product Passports with Digital Product Fingerprints offer you the peace of mind that you’re getting exactly what you paid for.',
        section_1_title: 'Redesigning ownership, provenance, and authenticity',
        section_1_text: 'for the real-world and Web3', 
        section_2_title: 'Redefining Art, Luxury, and Lifestyle',
        section_3_title: 'Your Professional Partner in Bridging Physical and Digital Worlds',
        subsection_3_text: 'Introducing Digital Product Passports (DPPs) with a unique twist – Digital Product Fingerprints (DPFs). Imagine having a unique, unbreakable lock and key system for your most valuable luxury goods. That\'s precisely what DPPs and DPFs offer!',
        subsection_3_text_2: '',
        section_4_title_1: 'Originality',
        section_4_text_1: 'DPFs are like the secret sauce that makes DPPs so powerful. We use advanced cryptographic technology to create a unique and unforgeable fingerprint for your product. This fingerprint is embedded in your DPP, ensuring it can\'t be tampered with or duplicated. No chips, no extra tracking of you and your valuable belongings.',
        section_4_title_2: 'Integrity',
        section_4_text_2: 'Each individual luxury product is endowed with a unique fingerprint as it emerges from the production line. These item-specific identifiers facilitate a seamless connection, first to the official distributors and subsequently to the buyers, thereby safeguarding the authenticity and ensuring the integrity of the ownership and provenance chain.',
        section_4_title_2_1: 'Authenticity',
        section_4_text_2_1: 'DPFs are like the secret sauce that makes DPPs so powerful. We use advanced cryptographic AI-enhanced technology to create a unique and unforgeable fingerprint for your product. This fingerprint is embedded in your DPP, ensuring it can\'t be tampered with or duplicated. No need for additional chips or intrusive tracking; it\'s all about safeguarding your valuable possessions.',
        section_4_title_3: 'Security and Trustworthiness',
        section_4_text_3: 'To enhance the security and trustworthiness of our products, we\'ve moved beyond RFID and NFC microchips to employ Product Fingerprints. With our proprietary technology, each product receives a distinctive digital signature, bolstering security. Our technology removes vulnerabilities associated with chips, such as susceptibility to hacking, ease of duplication, tampering risks, and the potential for removal. Our aim is to eliminate these vulnerabilities and provide robust protection for your invaluable possessions.',
        section_4_title_3_1: 'Privacy',
        section_4_text_3_1: 'At the heart of our approach lies a commitment to your privacy and protecting your valuable possessions. With our approach, your product\'s fingerprints remain inseparable from the product itself, eliminating any potential for surreptitious data collection. There\'s no need to be concerned about your personal data floating around on RFID or NFC chips. Your peace of mind is our top priority.',
        section_4_1_title_1: 'Exclusivity, Irreproducible Wonders',
        section_4_1_text_1: 'NFDIs are like true limited-editions of the digital world – there\'s only the authorized number of copies, and they can\'t be duplicated. That\'s the power of NFDIs!', 
        section_4_1_title_2: 'Real-Time Authentication',
        section_4_1_text_2: 'When you own an NFDI, you hold a piece of art with impeccable credentials. It\'s like having a perpetually live and verifiable certificate of authenticity that can\'t be counterfeited. With an NFDI, you can always be certain that you possess a genuine item. However, it\'s essential to maintain an active subscription to our services to enjoy these benefits.', 
        section_4_1_title_3: 'Sharing with Control',
        section_4_1_text_3: 'Here\'s the exciting part – NFDIs can be loaned between artists, museums, galleries, high net worth collectors, foundations, and other art lovers, but only on their terms. The owner decides the terms, so it\'s like lending your art to a friend, but with all the conditions you want in place.', 
        section_4_1_title_4: 'Digital Scarcity',
        section_4_1_text_4: 'Creating digital scarcity is one of the most challenging problems in computer science, and our proprietary solutions are designed precisely to address this issue. \n NFDIs, much like limited-edition prints of physical art, successfully achieve digital scarcity by existing in only a finite quantity of author\'s authorized digital images. This inherent scarcity enhances their intrinsic value and desirability. Owning an NFDI is similar to possessing a rare heirloom in the digital art world, where scarcity elevates its worth.', 
        section_4_1_title_5: 'Lasting Value',
        section_4_1_text_5: 'NFDIs have the power to maintain their value over time, just like limited-edition prints of physical art. So, not only do you get to enjoy stunning digital art, but you\'re also making a wise investment.',
        section_5_title: 'Reimagining Luxury Market',
        section_6_title: 'Building Unbreakable Connection Between Real Items and Their Digital Luxury Twins', 
        section_6_text: 'While having a Digital Product Passport (DPP) is undeniably valuable, a more substantial connection between the owner and the item is necessary. Think of it like possessing a unique key for a hidden treasure chest; merely having the key isn\'t enough—it must fit perfectly.\n This is where Digital Product Fingerprints come into play. Generated at the end of the manufacturing process, these fingerprints serve as a means of verification via a mobile device. They offer concrete proof of your item\'s authenticity, guaranteeing a seamless match and securing ownership.',
        section_6_title_1: 'Authenticity for Brands and Buyers',
        section_6_text_1: 'Our proprietary perpetual authentication of luxury goods’ avatars in the digital world makes them unique and digitally scarce. When a brand sells a limited edition of NFDIs linked to an exclusive bag or jewelry, only authentic NFDIs will be visibly and verifiably linked to the original. Anything else is just a copy that can\'t prove its authenticity.', 
        section_6_title_2: 'Digital Scarcity by Design',
        section_6_text_2: 'Our authenticated NFDIs perpetually retain the link to the brand’s anchor or their real-world prototype, ensuring limited availability in the Metaverse. In a world where everything can seem endlessly replicated, our NFDIs stand out as truly special. Our technology makes them like a limited edition of a valuable collectible, ensuring that only a select few can truly own a piece of the brand\'s magic in the Metaverse. It\'s a digital masterpiece that remains consistently rare and desirable.', 
        section_6_title_3: 'Enhanced Brands’ Visibility',
        section_6_text_3: 'NFDIs are like treasure chests of opportunities for luxury brands! They can create limited quantities of authorized, guaranteed, and authentic digital collectibles. This isn\'t just about making money; it\'s also about enhancing the brand\'s visibility in the market and the Metaverse. These unique digital items not only symbolize the brand\'s exclusivity but also resonate with tech-savvy art and luxury enthusiasts.', 
        section_6_title_4: 'Benefiting from Celebrity Culture',
        section_6_text_4: 'Our patented cutting-edge technology doesn\'t just authenticate NFDIs; it elevates their worth by connecting these digital treasures to the glamorous past of celebrity ownership of the real items. It\'s a game-changer for the NFT world, making NFDIs even more precious and appealing to those who truly appreciate the blend of luxury and celebrity heritage.', 
        section_6_text_1_nfdi: 'Non-Fungible Digital Images, NFDIs, designed for the Metaverse in a limited edition, are perpetually linked to the brand’s anchor. This connection ensures they\'re truly original and remain in high demand due to their digital scarcity.',
        section_6_title_1_dpp: 'Digital Product Fingerprints',
        section_6_text_1_dpp: 'Digital Product Fingerprints play a pivotal role as the immutable link connecting an owner to their item. When someone asserts ownership of a luxury item or artwork, it raises a key question: Is it really the same item they claim to possess? \n DPPs with embedded Digital Product Fingerprints ensure that the owner and the item match up perfectly. The verification process clearly confirms the legitimate owner of that specific item. In essence, it\'s like the final piece of the security puzzle, ensuring the integrity and safety of the entire system.',
        section_6_title_2_dpp: 'Confidentiality',
        section_6_text_2_dpp: 'Microchips have been employed to track various items like wine bottles, perfumes, or medicines during their journey. However, they come with their own set of vulnerabilities, such as susceptibility to removal, ease of duplication, and tampering risks. Additionally, if mishandled, they can reveal the location of your valuable luxury items to people who shouldn\'t know. \n NFTrends does things differently. Our technology leverages the inherent features of your items for authentication, eliminating the need for intrusive tracking methods like microchips. This keeps your valuables safe and your privacy intact.',
        section_6_title_3_dpp: 'Safety',
        section_6_text_3_dpp: 'Criminals might try to mess with RFID tags or sneakily grab your data from a distance. They can clone the chip and gain unauthorized access. But with NFTrends, we keep DPP off-chain, and it only comes out for authentication when you use your private key. Your stuff stays safe!',
        section_6_title_4_dpp: 'Guardians of Value over Time',
        section_6_text_4_dpp: 'Immutable DPPs with Digital Product Fingerprints help luxury items hold onto their value and ensure trustworthy resale markets, which are currently plagued by counterfeit goods. \n With these technologies, individuals and collectors can ensure their value stays strong and protect themselves from counterfeit products. So, when you have Immutable DPPs with Digital Product Fingerprints, your luxury item remains a star in the resale market, while the fakes gradually fade into the background.',
        section_6_text_5_dpp: 'Our special technology makes DPPs really smart. They can verify the previous ownership of a luxury item by famous people, and that can make it worth a lot more. It\'s like owning a watch that used to grace the wrist of a famous actor or a bag that Beyonce herself once carried!',
        section_7_title: 'How it works', 
        section_7_title_1: 'How we operate',
        section_7_text_1: 'We engage in direct partnerships with a diverse range of stakeholders, including luxury brands, museums, galleries, collectors, and artists, to facilitate the creation of Digital Product Passports. All participating parties are required to maintain a valid subscription to access our services. \n We meticulously capture object fingerprints, ensuring that each item is uniquely identified and documented within the digital realm. This fingerprinting process serves as a vital foundation for ensuring the authenticity and integrity of valuable assets in the physical and digital landscape. \n Furthermore, we harness cutting-edge technology to transform digital images into NFDIs. This proprietary innovative step enhances the uniqueness and traceability of visual representations, further solidifying the trustworthiness of digital assets.', 
        section_7_title_2: 'Initial Sale of Physical Items',
        section_7_text_2: 'Upon the initial sale of a physical item, ownership shifts from the IP holder, comprising brands and artists, to the buyer. Furthermore, the buyer is provided with a one-month complimentary Basic Subscription Plan to experience the unique features and benefits. To continue using our service beyond the trial period, the buyer must choose the subscription tier that aligns best with their needs.', 
        section_7_title_3: 'Secondary Sale of Physical Items',
        section_7_text_3: 'We facilitate secondary sales of physical items through collaboration with luxury brand retailers, galleries, and artists. In this process, the current owner authorizes access to the Digital Fingerprints of the item being sold. Our proprietary technology seamlessly validates its authenticity and ownership, ensuring a seamless transaction. The transfer of ownership for the Digital Product Passport occurs once the new buyer confirms receipt of the physical item at the authorized point of sale.', 
        section_7_title_4: 'Sale of NFDIs',
        section_7_text_4: 'To purchase an NFDI, a prospective buyer is required to initially establish an account with NFTrends. This account setup facilitates the smooth transfer of ownership for the digital item while guaranteeing its continuous synchronization with the physical prototype. Subsequently, after successfully creating an account with NFTrends, the buyer can proceed to purchase the desired NFDI through a participating platform. Nevertheless, it\'s essential to bear in mind that each participating platform may impose distinct prerequisites, such as ownership of specific cryptocurrencies or adherence to specific eligibility criteria.', 
        section_7_title_5: 'Management of Digital Assets',
        section_7_text_5: 'In our commitment to delivering the utmost security and adaptability in digital asset storage, including private keys, we extend various alternatives to buyers: Buyers have the flexibility to choose between storing their private keys with NFTrends or trusted digital asset custodians, enhancing security and instilling confidence in their holdings. Alternatively, buyers can opt to transfer their private keys to hardware devices, such as cold wallets. This approach grants them the pinnacle of security and complete control over their digital assets.', 
        section_7_title_6: 'Lending NFDIs',
        section_7_text_6: 'We provide a controlled and secure lending service for NFDIs, allowing museums, galleries, collectors, and exhibitions to borrow and lend these digital assets. To access this service, both the lender and borrower must hold valid subscriptions with NFTrends, granting them access to our exclusive lending suite. Within this suite, the lender can specify the terms and conditions of the loan in an art loan agreement. Since we maintain perpetual synchronization between NFDIs and the DPP for the original physical prototype, any breach of the agreement terms triggers an automatic return of the NFT to the lender. It\'s worth noting that NFTrends does not charge fees related to the financial aspects of the loan.', 
        section_7_title_7: 'Authenticity of NFDIs',
        section_7_text_7: 'We ensure authenticity and provenance by establishing an enduring real-time connection between an NFDI and the DPP associated with the original physical prototype. To ensure absolute irreproducibility, lenders may require the recipient to use custom-designed hardware compliant with our stringent specifications. The synchronization\'s verification can be visually confirmed by displaying a QR code alongside the exhibited NFDI. This QR code enables anyone to verify the authenticity and origin of the digital image by scanning it, linking to its actual ownership on the blockchain.', 
        section_8_title: 'We offer customized solutions for',
        section_8_text: 'NFDIs act as guardians of a brand\'s reputation, unlocking novel marketing opportunities, and enhancing the overall customer experience.',
        section_8_text_1: 'Digital Product Passports, paired with Digital Product Fingerprints, offer a cryptographically secure AI-enhanced record of a product\'s authenticity, origin, and ownership history. This robust system plays a pivotal role in safeguarding the brand\'s reputation and preserving its intrinsic value.',
        section_8_text_2: 'NFDIs innovate beyond the traditional use of NFTs to access extra perks from luxury brands. They introduce innovative revenue streams, such as the production of collectible, authentic limited-edition digital images that prominently display verifiable real-time official approval from the brand.',
        section_8_text_3: 'Our platform ensures luxury brands have complete participation in secondary sales, granting them control over the entire life cycle of unique luxury items.',
        section_9_text: 'NFDIs offer innovative avenues for artists to establish authenticity of their digital assets and enhance their ability to monetize their creative work.',
        section_10_text: 'NFTrends provides a proprietary, innovative, and comprehensive suite of solutions designed to facilitate the controlled, dynamic, and accessible exchange of artworks among museums, galleries, and collectors within the digital art and collectibles ecosystem.',
        section_11_text_1: 'For collectors, possessing an NFDI represents more than just having a digital file; it symbolizes the possession of a digital art or collectible item that carries the authentic real-time mark of its creator. This sense of ownership over something truly unique and valuable can be likened to possessing a rare limited-edition piece of art in the physical world.',
        section_11_text_2: 'The synergy between the Digital Product Passport and Digital Product Fingerprints provides buyers with the ability to confidently and transparently verify an item\'s authenticity. Furthermore, it actively involves intellectual property holders in secondary sales, enhancing the overall ecosystem.',
        section_12_title: 'Partners', 
        section_4_2_title: 'Transforming the World of Art',
        subsection_4_2_title: 'Ensuring Art\'s Authenticity, Democratizing and Tokenizing Art by Bridging the Physical and Digital Worlds',
        subsection_4_2_nfdi: 'With real-time authenticated NFDIs, we\'re shaping a new era for premium digital art—exclusive, IP-holders endorsed limited editions. They offer thrilling art sharing among collectors, galleries, and museums, along with controlled lending, now accessible to art enthusiasts of all kinds.',
        subsection_4_2_dpp: 'Established artists may inspire imitators, some of whom sign their own creations. But others try to ride on the fame of renowned artists, passing off their creations as those of famous artists. Digital Product Fingerprints decisively eliminate this practice.',
        contact_us_email: 'HelloNFTrends@nftrends.ai',
        contact_us_address: 'ADGM, Al Maryah Island, Abu Dhabi, UAE',
        get_question: 'Get all your questions answered', 
        book_free_success: 'Thank’s for your request. We’ll call you as soon as possible', 
        text_nfdi_1: 'NFDIs, or Non-Fungible Digital Images, are no ordinary images or NFTs; they\'re like digital treasures – a whole new level of unique and special. In a world of easy copies, NFDIs are a digital art lover\'s dream come true, combining uniqueness, authenticity, controlled sharing, rarity, and lasting value.',
        btn_dpp: 'Go to DPP',
        disclaimer_1: 'This image is an authentic digital image of a physical artwork created by the artist Amrita Sethi and is used here only for illustration. Currently, NFTrends has no working relations with the digital artist Amrita Sethi. To purchase this piece, please click the link', 
        disclaimer_2: 'This image is used only for illustration with the brand\'s permission. Currently, NFTrends has no working relations with the La Maison Steel. For further ordering and product information please click the link',
        subsection_5_2_title_1: 'Digital Product Fingerprints',
        subsection_5_2_text_1: 'Digital Product Fingerprints function as the guardian of your artwork, offering protection and security. These unique fingerprints establish an unbreakable connection between the artwork and its owner. They operate as a verifier, leaving no room for doubt or ambiguity regarding the rightful possessor of a particular piece. They serve to safeguard your collection, preserving its integrity and provenance.',
        subsection_5_2_title_2: 'Privacy Protection',
        subsection_5_2_text_2: 'While microchips offer a means to track various items, they also introduce a potential concern: the inadvertent exposure of your precious art collection to individuals who shouldn\'t have access. Here at NFTrends, we\'ve charted a different course—one that prioritizes safeguarding your collection while preserving your privacy at every step. \n Our unique approach sets us apart in the world of art authentication. We\'ve engineered a system that protects your collection without ever delving into your private matters. Instead of relying on intrusive methods, we harness the distinctive features that make your art truly yours for authentication.',
        subsection_5_2_title_3: 'Security',
        subsection_5_2_text_3: 'In the world of technology, there are those who may attempt to tamper with RFID tags or secretly snatch your data from afar. Some might even go as far as cloning the chip to produce counterfeited copies. But at NFTrends, we\'ve taken a proactive approach to safeguard your valuables. \n We\'ve designed our system to keep your DPP off-chain, tucked away securely until it\'s needed for authentication. It\'s like having a hidden vault that only opens when you use your private key. This way, your belongings remain protected, and you have peace of mind knowing that your valuable items are safe from prying eyes and unauthorized access.',
        subsection_5_2_title_4: 'Preservers of Art\'s Worth',
        subsection_5_2_text_4: 'In the ever-evolving art world, counterfeit artworks are a persistent concern. However, the presence of immutable DPPs with Digital Product Fingerprints serves as a bulwark against this issue, safeguarding the value of art and ensuring a reliable secondary market.\n In a landscape where the authenticity of artworks can often be uncertain, DPPs with their detective-like fingerprints act as vigilant guardians. They not only uphold the value of your art but also provide assurance that you won\'t fall victim to counterfeit pieces when buying or selling.',
        subsection_5_3_title_1: 'Choice Between Perpetual Authentication and Complete Irreproducibility',
        subsection_5_3_text_1: 'NFTrends pioneers an exclusive patent-pending technology that continuously syncs authorized NFDIs with their physical counterparts. Not only does it allow us to seamlessly verify NFDIs\' authenticity, but it also helps us to discern them from many imitations and unauthorized reproductions. \n Our quest for fully irreproducible NFDIs hinges on the utilization of specialized hardware. The implementation of this hardware, tailored to our rigorous standards, is straightforward and clearly defined. This ensures that NFDIs remain truly unique and resistant to duplication, maintaining their exclusivity and authenticity in the digital realm.',
        subsection_5_3_title_2: 'NFDIs and the Power of Digital Scarcity',
        subsection_5_3_text_2: 'The true value of high-end art lies in its rarity, but achieving it has been challenging. After all, digital art is essentially a file, which can be easily replicated. It became evident that simply posting an NFT on the blockchain didn\'t resolve the original issues it aimed to tackle—authenticity, provenance, and ownership. In fact, it exacerbated the problem by flooding the digital art with counterfeits and duplicates. \n Our solution provides a unique approach to this dilemma by perpetual authentication of NFDIs to the DPPs of their corresponding real-world artwork. This elevates NFDIs to the status of true artworks, endowing them with attributes like authenticity, provenance, ownership, and, most importantly, scarcity. We bridge the digital and physical art worlds, ushering in an era where digital art is as unique and sought-after as physical pieces.',
        subsection_5_3_title_3: 'Limited-Edition NFDIs',
        subsection_5_3_text_3: 'NFDIs introduce exciting prospects for IP holders, such as artists, museums, and galleries. They enable the creation of limited-edition art pieces with verifiable authenticity. These exclusive images are poised to retain their value over time, thanks to the inherent digital scarcity ensured by NFDIs. \n Furthermore, this innovation paves the way for museums and artists to explore novel revenue streams by producing authorized digital replicas of their physical art. It\'s a harmonious blend of preserving art\'s authenticity while tapping into new financial opportunities.',
        subsection_5_3_title_4: 'A Gateway to Distinctive Art Lending Experience',
        subsection_5_3_text_4: 'NFDIs create an unparalleled opportunity for art enthusiasts to loan and share artworks among museums, collectors, galleries, exhibitions, and other participants within the art community. Our innovative approach eradicates the traditional expenses linked to physical art lending, including insurance, transportation costs, and climate control. The lending suite allows art owners to define lending conditions and, once they are satisfied, securely transmit digital art pieces to the recipient. This is all done in a controlled manner, eliminating concerns about unauthorized copying of the files. \n The recipient proudly exhibits the NFDI on specialized hardware tailored to our standards, complete with authorized proof of authenticity recorded on the blockchain, prominently displayed alongside the digital image, affirming its genuine nature for all to see.',
        subsection_5_3_title_5: 'Global Access to Your Art',
        subsection_5_3_text_5: 'The power of creating genuine NFDIs of real art lies in the global accessibility it offers to art collectors. Imagine having the ability to carry your most cherished painting with you, no matter where you are in the world. This becomes possible by generating authorized copies and lending them to your various digital addresses, regardless of their geographic location. \n Moreover, if your physical artwork is securely stored in a vault or within a freeport zone, such as Geneva Freeport, you can now showcase its verifiably authentic NFDI in privacy of your own home. You retain complete control, ensuring that the displayed piece is always genuine. It\'s a seamless way to enjoy your art collection on a global scale while preserving the authenticity and security of each masterpiece.',
        subsection_5_3_title_6: 'Opportunities for Fractional Art Owners',
        subsection_5_3_text_6: 'Fractional art ownership takes on a new dimension with NFDIs, providing an exciting opportunity to exhibit the artwork for a duration that matches the ownership stake. This means they can not only partake in the financial benefits but also savor the tangible beauty of the art itself. \n When art security tokens are sold, the new owner gains the privilege to showcase the NFDI for the remaining duration. This mechanism ensures that the experience of owning a piece of art is truly shared among fractional owners, aligning the physical and digital realms of art ownership seamlessly.',
        subsection_4_disclaimer: `This image is an authentic digital image of a 
        physical painting created by the artist Kyu Nam Han, represented by
        the Anne-French Fine Arts Gallery, and is used here only for 
        illustration. Currently, NFTrends has no working relations with the 
        artist Kyu Nam Han, or with Anne-French Fine Arts Gallery. To 
        purchase this piece, please click the link`,
        subsection_5_disclaimer: `This image is an authentic digital image of a physical 
        painting created by the artist Anatoly Zverev, represented by the 
        Anne-French Fine Arts Gallery, and is used here only for illustration. 
        Currently, NFTrends has no working relations with the estate of 
        Anatoly Zverev, or with Anne-French Fine Arts Gallery. To purchase 
        this piece, please click the link`,
        reject_all: 'Reject All',
        accept_all: 'Accept All',
        customize: 'Customize',
        cookie_link_text: 'Cookie Policy',
        cookie_title: 'We value your privacy',
        cookie_text: 'Cookies are used to distinguish you and remember you and your preferences when you visit our Website.',
        save_preferences: 'Save my preferences',
        customize_title: 'Customize Consent Preferences',
    },
    onboarding: {
        title_1: 'It’s safe',
        text_1: 'The only place to collect your unique objects in safe',
        title_2: 'Verify Authenticity',
        text_2: 'Check your objects\' digital passports so you can be sure of their uniqueness.',
        title_3: 'Free month',
        text_3: 'Your one month free subscription starts now',
        skip: 'Skip',
        start: 'Start', 
        informer_text: 'Currently, login access is exclusively available through onboarded brands. If you intend to purchase luxury objects from participating brands or artwork from dealers utilizing NFTrends authentication technology with Digital Product Fingerprints, we kindly request that you reach out to them in advance to initiate the onboarding process.',
        title_moderation: 'Thank you for registering!',
        text_moderation: 'You have successfully registered in the system. Wait until the administrator confirms your registration.',
    },
    main: {
        about_1: 'NFtrends is a pioneering technology company dedicated to preserving the legacies behind the art and luxury worlds’ most valued creations. Created by world-renowned scientists and engineers, and led by art and luxury market experts, NFtrends offers a patented suite of non-invasive, AI-powered digital fingerprinting and blockchain product passport technologies that tackle the global collectible market’s most critical challenges—authentication and provenance.',
        about_2: 'NFtrends offers solutions that are as easy to use as they are powerful: their patented technology integrates seamlessly with existing systems, providing a turnkey solution that supports a sustainable circular economy and fosters trust. Designed for everyone from creators and collectors to luxury brands and cultural institutions, NFtrends empowers stakeholders to navigate the complexities of the modern market with confidence, knowing that their assets—and the legacies they carry—are secure.',
        solutions_title: 'Our solutions: unlocking the digital realm with advanced authentication and ownership verification', 
        solutions_title_1: 'Tangible Object AI Fingerprint',
        solutions_text_1_1: 'Whether you are a creator, brand, or owner of a precious object, NFtrends patented and proprietary AI digital fingerprints embedded into blockchain Digital Product Passports forge an unbreakable link between a physical asset and its digital twin.', 
        solutions_text_1_2: 'Our technology was developed by the world’s top data scientists to ensure your valuables, from paintings to luxury watches, are protected from counterfeiting and swapping.', 
        solutions_text_1_3: 'Next generation authentication with tamperproof digital fingerprints and immutable documentation of  provenance increases trust in both physical and virtual marketplaces — in both on and off chain transactions — paving the way for creators and brands to participate in their circular economy with verifiable authenticated creations.', 
        solutions_text_1_4: 'Our solution was designed for all stakeholders of the art & collectibles ecosystem: creators, collectors, platforms, and marketplaces.', 
        solutions_title_2: 'Digital Art Real-Time Authentication',
        solutions_text_2_1: 'Our proprietary solutions tackle digital scarcity challenges. NFtrends\'s authentication system ensures that only legitimate users can access authorized digital assets and artworks, enhancing trust and protecting artists\' copyrights.', 
        solutions_text_2_2: 'While artists want to sell their digital works as NFTs on the blockchain, they often struggle with unauthorized copying. Our solution guarantees that only authorized copies are displayed, with continuous synchronization to NFtrends’ infrastructure.', 
        solutions_text_2_3: 'Additionally, museums can use this technology to create and sell high-resolution, limited-edition digital reproductions of their physical artworks.', 
        solutions_title_3: 'Digital Product Passport',
        solutions_text_3_1: 'Unlike other Digital Product Passports, our DPPs are embedded with Object AI Fingerprints, leveraging advanced cryptographic and AI technologies to capture the unique, unforgeable surface structure of the object itself.', 
        solutions_text_3_2: 'Similar to biometrics in your passport, these AI fingerprints non-invasively and immutably link the object to its passport, ensuring authenticity and security.', 
        solutions_text_3_3: 'Additionally, our DPPs are tokenized for security within a digital asset wallet or custodian, preserving the chain of ownership for the future. This guarantees the object’s provenance is securely recorded, ensuring long-term protection and verifiability.',
        feature_title: 'Featured story',
        feature_text: 'Collaboration with OUCHHH, the Leading Innovative New Media Studio',
        discover_more: 'Discover more', 
        see_all_news: 'See all news',
        team_title: 'Meet the team', 
        team_subtitle: 'NFtrends was founded by world-renowned scientists who have pioneered critical technologies in blockchain, AI, and digital security.',
        team_title_1: 'Strategy & Business', 
        team_title_2: 'Technology',
        info_title: 'Empowering every stakeholder in the art ecosystem', 
        info_subtitle_1: 'Creators & Artists Estates', 
        info_text_1: 'Provide buyers with cutting-edge proof of authenticity using AI-generated digital fingerprints of your work, connected to an immutable blockchain digital product passport. This ensures ownership verification and a transparent chain of custody. Integrate with smart contract platforms that enforce resale rights.', 
        info_subtitle_2: 'Private Collectors, Museums, Corporate Collections', 
        info_text_2: 'Protect your investment by guaranteeing the authenticity of acquired artworks  and collectibles and preventing any swaps when your valuable piece is on loan.', 
        info_subtitle_3: 'Galleries, Art Fairs, Auction Houses', 
        info_text_3: 'Enhance transparency and credibility with tamperproof digital product passports for valuable collectibles and art. Accurately identify incoming and outgoing objects while creating permanent documentation for unquestionable history and provenance.', 
        info_subtitle_4: 'Ecosystem Professionals', 
        info_text_4: 'Ensure precise identification of incoming and outgoing objects. Seamlessly integrate with your system databases using our enterprise API to streamline tracking throughout the transport process.', 
        news_title: 'Recent news & press', 
        partners_title: 'Our partners', 
        contact_title: 'Contact us', 
        contact_text: 'Send us a message. We can’t wait to hear from you!', 
        main_card_title: 'Your legacy is our mission', 
        main_card_text: 'Born from the minds of world-renowned scientists and engineers, NFtrends’ patented technology provides the art and luxury worlds with innovative and proven solutions that protect the value and integrity of high-end creations.', 
        book_title: 'Book a free call',

    }
};

export default EN; 